import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { colors, headlineTwo, headlineThree, buttonOne } from "../Utilities"
import FishIcon from "../elements/FishIcon"

const BookBlockStyled = styled.section`
  width: 100%;
  padding: 2rem 2rem 5rem;
  background: ${colors.colorPrimary};
  text-align: center;

  .book-fish-icon {
    width: 100%;
    max-width: 14rem;
    margin: 1rem auto;
  }

  p {
    ${headlineThree};
    margin: 0;
    color: ${colors.white};
    text-transform: uppercase;
  }

  h3 {
    ${headlineTwo};
    margin: 0;
    color: ${colors.white};
    text-transform: uppercase;
  }

  .book-quinn-btn {
    margin: 3rem auto;
  }

  a {
    ${buttonOne};
  }
`

const BookBlock = () => {
  return (
    <BookBlockStyled>
      <div className="book-fish-icon">
        <FishIcon />
      </div>
      <p>Find Your Adventure</p>
      <h3>Book With Quinn</h3>
      <div className="book-quinn-btn">
        <Link to="/adventures">Book Now</Link>
      </div>
    </BookBlockStyled>
  )
}

export default BookBlock
