import React from "react"
import styled, { keyframes, css } from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { headlineThree, colors, bodyCopy } from "../Utilities"

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(2.5rem, 20rem) rotate(2.5deg);
  }
  10% {
    opacity: 0.1;
    transform: translate(1.25rem, 18rem) rotate(1.25deg);
  }
  20% {
    opacity: 0.2;
    transform: translate(0rem, 16rem) rotate(0deg);
  }
  30% {
    opacity: 0.3;
    transform: translate(-1.25rem, 14rem) rotate(-1.25deg);
  }
  40% {
    opacity: 0.4;
    transform: translate(-2.5rem, 12rem) rotate(-2.5deg);
  }
  50% {
    opacity: 0.5;
    transform: translate(-1.25rem, 10rem) rotate(-1.25deg);
  }
  60% {
    opacity: 0.6;
    transform: translate(0rem, 8rem) rotate(0deg);
  }
  70% {
    opacity: 0.7;
    transform: translate(1.25rem, 6rem) rotate(1.25deg);
  }
  80% {
    opacity: 0.8;
    transform: translate(0rem, 4rem) rotate(0deg);
  }
  90% {
    opacity: 0.9;
    transform: translate(-1.25rem, 2rem) rotate(-1.25deg);
  }
  100% {
    opacity: 1;
    transform: translate(0rem, 0rem) rotate(0deg);
  }
`

const complexMixin = css`
  animation-name: ${fadeIn};
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  opacity: 0;
`

const PostCardStyled = styled.article`
  ${complexMixin};
  width: calc(100% - 4rem);
  margin: 3rem 2rem;

  @media (min-width: 825px) {
    width: calc(33.33% - 4rem);
    margin: 2rem;
  }

  a {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .post-img {
    width: 100%;
  }

  .post-details {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2.5rem;
    background: ${colors.colorPrimary};
    text-align: center;

    h3 {
      ${headlineThree};
      width: 100%;
      margin: 0;
      color: ${colors.white};
      text-transform: uppercase;
    }

    p {
      ${bodyCopy};
      width: 100%;
      margin: 0;
      color: ${colors.white};
      text-transform: uppercase;
    }

    &-1 {
      background: ${colors.colorAccent};
    }

    &-2 {
      background: ${colors.colorPrimary};
    }

    &-3 {
      background: ${colors.colorTertiary};
    }
  }
`

const PostCard = ({ post }) => {
  let classNum = Math.floor(Math.random() * 3) + 1
  const imageValue = post.acf._dow_featured_image ? true : null
  return (
    <PostCardStyled key={post.wordpress_id}>
      <Link to={`/fly-box/${post.slug}`}>
        <div className="post-img">
          {imageValue && (
            <Img
              fluid={
                post.acf._dow_featured_image.localFile.childImageSharp.fluid
              }
              alt={post.acf._dow_featured_image.alt_text}
            />
          )}
        </div>
        <div className={`post-details post-details-${classNum}`}>
          <div>
            <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
            {post.date && <p>{post.date}</p>}
            {post.acf._dow_author && <p>Written By: {post.acf._dow_author}</p>}
          </div>
        </div>
      </Link>
    </PostCardStyled>
  )
}

export default PostCard
