import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage"
import ImageQuoteContent from "../components/ImageQuoteContent"
import AdventureRow from "../components/AdventureRow"
import BookBlock from "../components/BookBlock"
import ProudPart from "../components/ProudPart"
import LatestQuinn from "../components/LatestQuinn"

const IndexPage = (props) => {
  const metaTitle = props.data.page.acf._dow_meta_title
  const metaDescription = props.data.page.acf._dow_meta_description
  const metaImage = props.data.page.acf._dow_meta_image.localFile.publicURL

  const heroFluid =
    props.data.page.acf._dow_hero_image.localFile.childImageSharp.fluid
  const displayLogo = props.data.page.acf._dow_hero_main_logo

  const IQCData = {}
  IQCData.fluid =
    props.data.page.acf._dow_iqc_image.localFile.childImageSharp.fluid
  IQCData.altText = props.data.page.acf._dow_iqc_image.alt_text
  IQCData.quote = props.data.page.acf._dow_iqc_quote
  IQCData.title = props.data.page.acf._dow_iqc_title
  IQCData.content = props.data.page.acf._dow_iqc_content

  const quinnsGearImg = props.data.page.acf._dow_quinns_gear_image

  const proudPart = props.data.page.acf.proud_part_logos

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "ViewContent", { content_name: "Homepage" })
      }
    }
  }, [])

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage imgFluid={heroFluid} displayLogo={displayLogo} />
      <ImageQuoteContent data={IQCData} />
      <AdventureRow />
      <BookBlock />
      <ProudPart data={proudPart} />
      <LatestQuinn quinnsGearImg={quinnsGearImg} />
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    page: wordpressPage(slug: { eq: "home" }) {
      acf {
        _dow_meta_title
        _dow_meta_description
        _dow_meta_image {
          localFile {
            publicURL
          }
        }
        _dow_hero_main_logo
        _dow_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _dow_iqc_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        _dow_iqc_quote
        _dow_iqc_title
        _dow_iqc_content

        _dow_quinns_gear_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        proud_part_logos {
          logo {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          url
        }
      }
    }
  }
`

export default IndexPage
