import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import PostCard from "./PostCard"
import FlyHookTwo from "../elements/FlyHookTwo"
import FlyLineOne from "../elements/FlyLineOne"
import { medWrapper, headlineThree, headlineTwo, colors } from "../Utilities"

const getData = graphql`
  query latestQuinn {
    posts: allWordpressPost(limit: 2) {
      edges {
        node {
          date(formatString: "MMMM D, Y")
          title
          slug
          wordpress_id
          acf {
            _dow_author
            _dow_featured_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const LatestQuinnSection = styled.section`
  position: relative;
  margin-bottom: 5rem;

  .lq-wrapper {
    ${medWrapper};
  }

  .lq-title {
    position: relative;
    width: 100%;
    margin: 2rem auto 4rem;
    padding-top: 10rem;

    p {
      ${headlineThree};
      margin: 0;
      color: ${colors.colorSecondary};
      text-align: center;
      text-transform: uppercase;
    }

    h2 {
      ${headlineTwo};
      margin: 0;
      color: ${colors.colorPrimary};
      text-align: center;
      text-transform: uppercase;
    }

    .fly-hook-img {
      position: absolute;
      top: -5rem;
      right: 0;
      left: 0;
      width: calc(60rem / 4);
      height: calc(70rem / 4);
      margin: auto;
      z-index: -1;
    }
  }

  .lq-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .fly-line-icon {
    position: absolute;
    top: -2rem;
    right: calc(51% + 1rem);
    width: calc(117vw / 1.5);
    height: calc(59.7vw / 1.5);
    margin: auto;
    z-index: -1;
  }
`

const LatestQuinn = props => {
  const data = useStaticQuery(getData)
  const {
    posts: { edges: posts },
  } = data

  const QuinnsGear = [
    {
      node: {
        wordpress_id: Math.floor(Math.random() * 10000000) + 10000,
        slug: "#quinnGear",
        title: "Quinn's Gear",
        date: false,
        acf: {
          _dow_author: false,
          _dow_featured_image: props.quinnsGearImg,
        },
      },
    },
  ]

  if (posts.length < 3) {
    posts.splice(1, 0, QuinnsGear[0])
  }

  return (
    <LatestQuinnSection>
      <div className="lq-wrapper">
        <div className="lq-title">
          <p>My Gear / Fly Box</p>
          <h2>Check Out The Latest From Quinn</h2>
          <div className="fly-hook-img">
            <FlyHookTwo />
          </div>
        </div>
        <div className="lq-posts">
          {posts.map((post, index) => {
            return <PostCard key={index} post={post.node} index={index} />
          })}
        </div>
      </div>
      <div className="fly-line-icon">
        <FlyLineOne />
      </div>
    </LatestQuinnSection>
  )
}

export default LatestQuinn
