/* eslint-disable */
import React, { Component } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { TimelineMax, Power0, CSSPlugin, AttrPlugin } from "gsap/all"
import ScrollMagic from "scrollmagic"
import animationGsap from "animation.gsap"

import { medWrapper, headlineOne, colors, bodyCopy } from "../Utilities"
import FlyHookOne from "../elements/FlyHookOne"

const plugins = [CSSPlugin, AttrPlugin, animationGsap]

const ImageQuoteContentSection = styled.section`
  padding: 2rem 0;

  @media (min-width: 768px) {
    padding: 3.5rem 0;
  }

  @media (min-width: 1025px) {
    padding: 5rem 0;
  }

  .iqc-wrapper {
    ${medWrapper};
  }

  .iqc-imagequote {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 5rem);
      margin-right: 5rem;
    }

    .iqc-img {
      width: 100%;
      margin-bottom: 1rem;
    }

    .iqc-quote {
      background: ${colors.colorTertiary};
      text-align: center;
      padding: 5rem;

      p {
        ${headlineOne};
        margin: 0;
        color: ${colors.white};
        opacity: 0.17;
      }
    }
  }

  .iqc-content {
    position: relative;
    align-self: center;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50%);
      padding: 4rem;
    }

    h2 {
      ${headlineOne};
      color: ${colors.colorSecondary};
      text-transform: uppercase;
    }

    p {
      ${bodyCopy};
    }

    .iqc-flyfish-hook {
      position: absolute;
      bottom: -10rem;
      right: 0;
      width: calc(60rem / 3);
      height: calc(54.5rem / 3);
      z-index: -1;
    }
  }
`

class ImageQuoteContent extends Component {
  constructor(props) {
    super(props)

    this.timeline = new TimelineMax()
    this.sceneAnimation = this.sceneAnimation.bind(this)
  }

  sceneAnimation(tl) {
    const controller = new ScrollMagic.Controller()
    new ScrollMagic.Scene({
      triggerElement: "#scrollStarts",
      duration: "100%",
      offset: 200,
    })
      .setTween(tl)
      .addTo(controller)
    //.addIndicators()
  }

  componentDidMount() {
    this.timeline.fromTo(
      "#myElement",
      1,
      { y: "25%", scale: 1, ease: Power0.easeNone },
      { y: "-50%", scale: 1.1, ease: Power0.easeNone }
    )

    this.sceneAnimation(this.timeline)
  }

  render() {
    const { fluid, altText, quote, title, content } = this.props.data
    return (
      <ImageQuoteContentSection>
        <div id="scrollStarts" className="iqc-wrapper">
          <div className="iqc-imagequote">
            <div className="iqc-img">
              <Img fluid={fluid} alt={altText} />
            </div>
            <div className="iqc-quote">
              <p>{quote}</p>
            </div>
          </div>
          <div className="iqc-content">
            <h2>{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div id="myElement" className="iqc-flyfish-hook">
              <FlyHookOne />
            </div>
          </div>
        </div>
      </ImageQuoteContentSection>
    )
  }
}

export default ImageQuoteContent
